import { graphql } from "gatsby";
import React from "react";
import { Container, Jumbotron } from "react-bootstrap";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import SpotlightPreviewGrid from "../components/spotlight-preview-list";
import Layout from "../containers/layout";
import { mapEdgesToNodes } from "../lib/helpers";

export const query = graphql`
  query SpotlightPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      _rawLogo
    }
    spotlights: allSanitySpotlight(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          id
          isReady
          network {
            title
            slug {
              current
            }
            _rawLogo
            brandColors
          }
          intro {
            _rawSimpleWhatItIs
          }
        }
      }
    }
  }
`;

const LearnPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const spotlightNodes = mapEdgesToNodes((data || {}).spotlights);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout _rawLogo={site._rawLogo}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Jumbotron fluid>
        <Container>
          <h1 className="text-center text-sm-left">
            <div>Learn about cryptonetworks.</div>
            Capitalise on their opportunities.
          </h1>
        </Container>
      </Jumbotron>
      <Container>
        {spotlightNodes && (
          <SpotlightPreviewGrid
            title="Spotlights"
            listType="panel"
            nodes={spotlightNodes.filter(spotlight => spotlight.isReady)}
          />
        )}
      </Container>
    </Layout>
  );
};

export default LearnPage;
